@import "~antd/dist/antd.css";

@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

.App {
  text-align: center;
}

body {
  font-family: "Poppins";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.ant-layout {
  background: linear-gradient(
    117.83deg,
    #f3fbff 10.85%,
    #b3dcf9 96.06%
  ) !important;
}

.ant-menu {
  background: #fff !important;
}
.employee_row_edit {
  width: 8rem;
}
.ant-btn-compact-item {
  border: none;
  background-color: transparent;
  transform: rotate(90deg);
}
.ant-dropdown-menu-vertical {
  width: 6rem !important;
  transform: translate(28px, -12px);
}
.ant-dropdown-menu-title-content {
  font-size: 12px;
  font-family: "Inter";
  font-weight: 600;
  color: red;
}
.ant-menu > .ant-menu-item {
  height: 72px;
  margin: 0;
  line-height: initial;
  padding: 0;
  display: flex;
  align-items: center;
  /* text-align: center; */
  border-left: 3px solid transparent !important;
  border-right: 3px solid transparent !important;
}
.mission_dropdown .ant-dropdown-menu {
  overflow-y: auto;
  width: 284px;
  height: auto;
  max-height: 260px;
  top: 12px;
  left: -240px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.ant-dropdown-menu {
  overflow-y: auto;
  width: 284px;
  height: auto;
  max-height: 260px;
  top: 12px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.row_edit .ant-dropdown-menu {
  width: 180px;
  height: 44px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  top: 0px;
  display: flex;
  align-items: center;
}

.row_edit .ant-dropdown-menu-item {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: red;
  width: 100%;
  background-color: transparent;
}
.actionplan_dropdown .ant-dropdown-menu {
  width: 100%;
  height: auto;
  max-height: 260px;
  background: #ffffff;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  overflow-y: auto;
}

.header_dropdown .ant-dropdown-menu {
  width: 180px;
  height: initial;
}
.ant-checkbox-inner {
  width: 1.25rem;
  height: 1.25rem;
}

.ant-dropdown-menu::-webkit-scrollbar {
  width: 7px;
}
.ant-dropdown-menu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ca717800;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  background: #ffffff;
}
.ant-dropdown-menu::-webkit-scrollbar-thumb {
  background: #b7babb;
  border-radius: 10px;
}
.ant-dropdown-menu::-webkit-scrollbar-thumb:hover {
  background: #b7babb;
}
.ant-dropdown-menu {
  scroll-behavior: smooth;
}
.ant-menu > .ant-menu-item-open,
.ant-menu > .ant-menu-submenu-open,
.ant-menu > .ant-menu-item-selected,
.ant-menu > .ant-menu-submenu-selected {
  background-color: #1271a6 !important;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title {
  padding-left: 0%;
  padding-right: 0%;
}

.ant-menu-inline-collapsed-tooltip a,
.ant-menu-inline-collapsed-tooltip a > span {
  color: #fff !important;
}

.ant-menu-inline-collapsed-tooltip a,
.ant-menu-inline-collapsed-tooltip a > img {
  filter: brightness(0) invert(1) !important;
}

.ant-menu > .ant-menu-item-open svg path,
.ant-menu > .ant-menu-submenu-open svg path,
.ant-menu > .ant-menu-item-selected svg path,
.ant-menu > .ant-menu-submenu-selected svg path {
  fill: #1890ff;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  display: inline-block;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1689ca;
  border-color: #1689ca;
}

.ant-checkbox-inner::after {
  margin-left: 2px;
}

.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
  padding: 0% !important;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0% !important;
}

.ant-menu-inline .ant-menu-selected::after,
.ant-menu-inline .ant-menu-item-selected::after {
  opacity: 0 !important;
}

.ant-select-selector {
  border-radius: 30px !important;
  border-color: #fff !important;
  border: none !important;
  background-color: #fff !important;
}

.ant-select-open {
  border-radius: 30px !important;

  /* background-color: #5D83AB !important; */
}

/* .ant-select-open > div > .ant-select-selection-placeholder {
  color: #fff !important;
} */

.ant-select.ant-select-single {
  border: 1px solid #e3e5e9;
  border-radius: 4px !important;
  max-width: 284px;
  width: 100%;
}

/* @media screen and (max-width: 865px) {
  .ant-select.ant-select-single {
    width: 224px;
  }
} */

@media screen and (max-width: 743px) {
  .ant-select.ant-select-single {
    width: 180px;
  }
}

.ant-select-selector {
  padding: 3px 12px !important;
  cursor: pointer !important;
  height: initial !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  background-repeat: no-repeat;
  background-position: calc(100% - 12px) center;
  background-image: url("./assets/images/arrow-down.svg");
}

.ant-select-selection-placeholder {
  font-family: "Inter";
  color: #8e97a3 !important;
  font-size: 14px;
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ant-select-arrow {
  display: none !important;
}

.ant-select-item {
  text-transform: uppercase;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #1b2a3d;
  padding: 10px 20px;
}
.ant-select-item:hover {
  background: #ececec;
}

.ant-select-dropdown {
  padding: 8px 0;
  background: #fff;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.ant-select-item.ant-select-item-group {
  text-transform: initial;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #000;
  padding: 10px 10px 0 10px;
}

.darkSelector > .ant-select-selector > span {
  color: #1271a6 !important;
}

.darkSelector > .ant-select-open > .ant-select-arrow {
  color: #1271a6 !important;
  text-decoration-line: underline;
}

.darkSelector > .ant-select-selection-placeholder {
  color: #1271a6 !important;
  text-decoration-line: underline;
}

.darkSelector > .ant-select-arrow {
  color: #1271a6 !important;
}

.whiteSelector {
  margin-top: 10px;
  margin-bottom: -10px;
}

.whiteSelector > .ant-select-selector > span {
  color: #fff !important;
  font-size: 12px;
}

.whiteSelector > .ant-select-open > .ant-select-arrow {
  color: #fff !important;
  text-decoration-line: underline;
}

.whiteSelector > .ant-select-selection-placeholder {
  color: #fff !important;
  text-decoration-line: underline;
}

.whiteSelector > .ant-select-arrow {
  color: #fff !important;
}

.actionItemCollapse > div > div {
  width: 100%;
}

.actionItemCollapse .ant-collapse-content {
  position: relative;
  z-index: 9;
}

.ant-table-thead .ant-table-cell {
  background-color: #2d8cc1;
  color: #fff;
  text-transform: capitalize !important;
  text-decoration-line: none !important;
}

.deleteModal > .ant-modal-content > .ant-modal-body {
  padding: 0px;
}

.modalStyle > .ant-modal-content > .ant-modal-header > div {
  font-weight: bold !important;
}

.ant-table {
  border-radius: 5px !important;
}

.editable-row {
  font-weight: bold;
}

.ant-table-content {
  border-radius: 5px !important;
}

.firstCell {
  border-left: 5px solid #f4771b !important;
}

.orangeText {
  color: #f4771b;
}

.ant-tag-has-color,
.ant-tag-has-color a,
.ant-tag-has-color a:hover,
.ant-tag-has-color .anticon-close,
.ant-tag-has-color .anticon-close:hover {
  color: #feab3d;
}

.ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
  color: #1689ca !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #feab3d !important;
}

.ant-tabs-ink-bar {
  background: #feab3d !important;
}

.ant-table-tbody .ant-table-cell {
  background-color: #fffef6;
}

.actionItemHeader .ant-table-cell {
  background-color: #fff;
  border: 1px solid #eff7fe;
  padding: 0 12px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.categoryTable .ant-table-cell {
  background-color: #f9f9f9;
  border: 1px solid #eff7fe;
}

.categoryTable .ant-table-thead .ant-table-cell {
  background-color: #1271a6;
  color: #fff;
  text-transform: uppercase !important;
  text-decoration-line: none !important;
}

.categoryTable .ant-table-content {
  border-radius: 5px !important;
}

.actionItemHeader > div > div > div {
  margin-left: -8px !important;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

@media screen and (min-width: 744px) {
  ::-webkit-scrollbar {
    width: 5px;
    height: 2px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #112538;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #112538;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ant-progress {
  display: block;
}

.ant-progress-outer {
  display: block;
}

.ant-progress-inner {
  display: block;
}

.ant-tabs-nav::before {
  border-bottom-color: #c0d3e7 !important;
}
